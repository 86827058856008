<template>
  <component :is="layout">
      <router-view />
  </component>
</template>

<script>
import { lightOrDarkStore } from '@/stores/lightOrDark';

const DefaultLayout = 'main'

export default {
  name: 'App',
  computed: {
    layout () {
      return (this.$route.meta.layout || DefaultLayout) + '-layout'
    }
  },
  setup() {
    const lightOrDark = lightOrDarkStore();

    return {
      lightOrDark
    };
  },
  data() {
    const tabTarget = this.$route.meta.target || 'home';

    return {
      tabTarget
    };
  },
  mounted() {
    document.body.classList.add("light");
  },
  unmounted() {
    document.body.classList.remove("dark");
  }
}

</script>
