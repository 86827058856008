import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from 'pinia';
import i18n from "./locales/i18n";
import router from "./router";
import axios from 'axios';
import VueAxios from 'vue-axios';
import { createHead } from "@vueuse/head";

import MainLayout from './layouts/MainLayout.vue';

import "./assets/scss/main.scss";

const pinia = createPinia();
const app = createApp(App);

app.use(VueAxios, axios);
app.use(pinia);
app.use(i18n);
app.use(router);
app.use(createHead());

app.component('main-layout', MainLayout);

router.isReady().then(() => app.mount("#app"));
