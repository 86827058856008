<template>
  <div class="locale-switcher">
    <button class="selected-locale">
      <i :class="'fi fi-' + getFlagByLocale($i18n.locale)"></i>
    </button>
    <h4>
      <span 
        v-for="(locale, key) in localesList" 
        :key="key" 
        :class="'fi fi-' + locale.flag" 
        :title="locale.label"
        @click="selectLocale(key)"></span>
    </h4>
  </div>
</template>

<script>
import supportedLocales from '@/configs/supported-locales';

export default {
    data() {
        const localesList = supportedLocales;
        
        return {
            localesList
        };
    },
    methods: {
      selectLocale(locale) {
        document.body.classList.add('disappear');
        let localThis = this;

        setTimeout(function() {
          localThis.$i18n.locale = locale;
          localThis.$router.push({ 
            params: { lang: locale }
          });
          document.body.classList.remove('disappear');
        }, 350);
      },
      getFlagByLocale(locale) {
        if(locale && this.$i18n.availableLocales.includes(locale)) {
          return supportedLocales[locale].flag;
        }
        else {
          return 'it';
        }
      }
    }
};
</script>
