<template>
  <div class="demo-sticker logo">
    <LogoBrand />
  </div>
  <div class="demo-sticker locales-switch">
    <LocalesSwitch />
  </div>
  <div
    className="demo-sticker"
    :title="
      $t('menu.switch') +
      (lightOrDark.isDark ? $t('menu.temaChiaro') : $t('menu.temaScuro'))
    "
  >
    <a @click="lightOrDark.switchStatus()">
      <i :className="'fa fa-' + lightOrDark.iconClass" aria-hidden="true"></i>
    </a>
  </div>
  <!-- End switcher -->
  <header class="header">
    <ul
      class="
        nav
        icon-menu
        d-lg-block
        revealator-slideup revealator-once revealator-delay1
      "
      id="myTab"
    >
      <li class="icon-box" role="presentation">
        <router-link to="/" id="home">
          <i class="fa fa-home"></i>
          <h2>{{ $t("menu.home.menu") }}</h2>
        </router-link>
      </li>
      <!-- End icon-box -->

      

      <li class="icon-box" role="presentation">
        <router-link to="/contatti" id="contact">
          <i class="fa fa-envelope-open"></i>
          <h2>{{ $t("menu.contacts.menu") }}</h2>
        </router-link>
      </li>
      <!-- End icon-box -->
    </ul>
  </header>
  <!-- End header -->
</template>

<script>
import { lightOrDarkStore } from "@/stores/lightOrDark";
import LogoBrand from "@/components/LogoBrand.vue";
import LocalesSwitch from "@/components/LocalesSwitch.vue";

export default {
  components: {
    LogoBrand,
    LocalesSwitch,
  },
  mounted() {
    const currentRoute = this.$route;
    
    this.setActivePage(true, currentRoute);
  },
  setup() {
    const lightOrDark = lightOrDarkStore();

    return {
      lightOrDark,
    };
  },
  methods: {
    setActivePage(isAddClass, oRoute) {
      if (oRoute && oRoute.meta && oRoute.meta.target) {
        var target = document.querySelector("#myTab  #" + oRoute.meta.target);
        if(target) {
          if(isAddClass) {
            target.classList.add("active");
          }
          else {
            target.classList.remove("active");
          }
        }
      }
    }
  },
  watch: {
    $route(to, from) {
      this.setActivePage(false, from);

      this.setActivePage(true, to);
    },
  },
};
</script>
