import { defineStore } from 'pinia';

// You can name the return value of `defineStore()` anything you want, but it's best to use the name of the store and surround it with `use` and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const lightOrDarkStore = defineStore('lightOrDark', {
    state: () => ({ 
        isDark: false,
        iconTitle: 'Dark',
        iconClass: 'moon-o',
        bodyClass: 'light'
     }),
    getters: {
        getIsDark: (state) => state.isDark,
        getIsLightFn: (state) => !state.isDark,
        getIconTitle: (state) => state.iconTitle,
        getIconClass: (state) => state.iconClass,
        getBodyClass: (state) => state.bodyClass,
    },
    actions: {
        switchStatus() {
            this.isDark = !this.isDark;

            if(this.isDark) {
                this.iconTitle = 'Light';
                this.iconClass = 'lightbulb-o';
                this.bodyClass = 'light';
                document.body.classList.add("dark");
                document.body.classList.remove("light");
            }
            else {
                this.iconTitle = 'Dark';
                this.iconClass = 'moon-o';
                this.bodyClass = 'dark';
                document.body.classList.add("light");
                document.body.classList.remove("dark");
            }
        },
    },
});