import { createI18n } from 'vue-i18n';
import supportedLocales from '@/configs/supported-locales';
import { messagesIT } from '@/locales/it';
import { messagesEN } from '@/locales/en';

function getLocalesKey() {
    let localeKeys = [];

    for(const prop in supportedLocales) {
        localeKeys.push(prop);
    }

    return localeKeys;
}

let i18n = createI18n({
    locale: 'it',
    fallbackLocale: 'it',
    legacy: false,
    availableLocales: getLocalesKey(),
    messages: {
        it: messagesIT,
        en: messagesEN
    }
});

export default i18n;