import configs from "@/configs/configs";
import contacts from "@/configs/contacts-data";

export const datesIT = {

};

export const numbersIT = {

};

export const messagesIT = {
    interfaccia: {
        paginator: {
            prev: 'Indietro',
            next: 'Avanti'
        }
    },
    menu: {
        switch: 'Tema ',
        temaScuro: 'Scuro',
        temaChiaro: 'Chiaro',
        home: {
            menu: 'Home',
            title: 'Home',
            meta: {
                description: 'All You Can Web realizza siti web per privati e aziende, loghi, campagne AdWords, campagne marketing varie e tutto ciò che può riguardare il web'
            }
        },
        about: { 
            menu: 'Chi siamo',
            menuClass: 'aboutMenuIT',
            title: 'La storia e il team',
            meta: {
                description: 'Qual è la storia di All You Can Web e chi sono le persone che compongono il team: sviluppatori, grafici, illustratori e commerciali'
            }
        },
        portfolio: {
            menu: 'Portfolio',
            title: 'I lavori per i nostri clienti',
            meta: {
                description: 'Tutti i lavori realizzati da All You Can Web per i propri clienti'
            }
        },
        portfolioDetail: {
            title: '',
            meta: {
                description: 'Dettaglio del lavoro'
            }
        },
        blog: {
            menu: 'Blog',
            title: 'Articoli, notizie e novità',
            meta: {
                description: 'Articoli, notizie e novità del mondo All You Can Web'
            }
        },
        blogDetail: {
            title: '',
            meta: {
                description: 'Dettaglio dell\'articolo'
            }
        },
        contacts: {
            menu: 'Contatti',
            title: 'Contattaci',
            meta: {
                description: 'Tutti i riferimenti di All You Can Web comprensivi di numero di telefono, indirizzo email e modulo di contatto'
            }
        },
    },
    home: {
        pageTitle: 'Home - ' + configs.brandName,
        titolo: configs.brandName,
        sottotitolo: 'Chopstick dev',
        description: 'Non abbiamo un prezzo fisso, ma possiamo gestire tutto quello che volete in chiave web: realizzazione di siti web per privati e per aziende, attività di SEO e indicizzazione, sviluppo di gestionali, illustrazioni, realizzazioni grafiche, loghi e molto altro ancora!',
        pulsanti: {
            about: 'Di più su di noi',
            contact: 'Contattaci'
        }
    },
    about: {
        pageTitle: 'Chi siamo - ' + configs.brandName,
        titolo: 'All You Can <span>Web</span>',
        sottotitolo: 'La vision',
        personalInfo: 'Info personali',
        intro: '',
        teamTitle: 'Chi <span>siamo</span>',
        teamSubtitle: 'Il Team',
        downloadCV: 'Scarica CV',
        name: 'Nome',
        surname: 'Cognome',
        birthday: 'Data di nascita',
        mail: 'Mail',
        phone: 'Telefono',
        website: 'Sito web',
        skillsTitle: 'Competenze',
        intros: {
            giordan: 'Sviluppatore web',
            serena: 'Illustratrice',
            mauro: 'Graphic designer',
            simon: 'Copywriter, editor, correttore di bozze e scrittore. Collabora con agenzie editoriali e case editrici e si occupa di SEO, creando contenuti testuali ottimizzati e rinforzando l\'identità e la visibilità delle aziende.'
        },
        numbers: {
            giordan: {
                data: {
                    phone: contacts.methods.getPhoneLink('giordan'),
                    mail: contacts.methods.getMailLink('giordan'),
                    website: contacts.methods.getWebsiteLink('giordan')
                }
            },
            serena: {
                data: {
                    phone: contacts.methods.getPhoneLink('serena'),
                    mail: contacts.methods.getMailLink('serena'),
                    website: contacts.methods.getWebsiteLink('serena')
                }
            },
            mauro: {
                data: {
                    phone: contacts.methods.getPhoneLink('mauro'),
                    mail: contacts.methods.getMailLink('mauro'),
                    website: contacts.methods.getWebsiteLink('mauro')
                }
            },
            simon: {
                data: {
                    phone: contacts.methods.getPhoneLink('simon'),
                    mail: contacts.methods.getMailLink('simon'),
                    website: contacts.methods.getWebsiteLink('simon')
                }
            },
            fabrizio: {
                data: {
                    phone: contacts.methods.getPhoneLink('fabrizio'),
                    mail: contacts.methods.getMailLink('fabrizio'),
                    website: contacts.methods.getWebsiteLink('fabrizio')
                }
            },
            achievements: {
                workingYears: {
                    text1: 'Anni di',
                    text2: 'esperienza'
                },
                completeProject: {
                    text1: 'Progetti',
                    text2: 'completati'
                },
                happyClients: {
                    text1: 'Clienti',
                    text2: 'soddisfatti'
                },
                awardsWon: {
                    text1: 'Premi',
                    text2: 'vinti'
                }
            }
        }
    },
    portfolio: {
        pageTitle: 'Portfolio - ' + configs.brandName,
        titolo: 'Il <span>Portfolio</span>',
        sottotitolo: 'Lavori'
    },
    contacts: {
        pageTitle: 'Contatti - ' + configs.brandName,
        titolo: 'Restiamo in <span>contatto</span>',
        sottotitolo: 'Contatti',
        payoff: 'Non essere timido!',
        description: 'Non perdere l\'occasione di rimanere in contatto con noi. Siamo sempre aperti a discutere nuovi progetti, idee creative od opportunità ed essere parte della tua vision!',
        mailLong: 'Indirizzo e-mail',
        cellPhone: 'Cellulare',
        writeMe: 'Scrivimi',
        callMe: 'Chiamami',
        form: {
            nome: 'Il tuo nome',
            mail: 'La tua email',
            oggetto: 'Motivo del contatto',
            msg: 'Il tuo messaggio',
            send: 'INVIA MESSAGGIO',
            sending: 'INVIO IN CORSO...'
        }
    },
    blog: {
        pageTitle: 'Blog e notizie - ' + configs.brandName,
        titolo: 'Blog e <span>notizie</span>',
        sottotitolo: 'Post vari',
    },
    notification: {
        buttons: {
            close: 'Chiudi'
        },
        success: {
            contact: 'Messaggio inviato correttamente'
        },
        warning: {
            confirmContactMail: 'Il messaggio è stato inviato, anche se abbiamo avuto qualche problema a recapitarti la conferma alla tua mail <i class="fa fa-meh-o"></i>'
        },
        error: {
            contactMail: 'Ooops... non siamo riusciti a mandare il messaggio. Riprova più tardi <i class="fa fa-frown-o"></i>'
        }
    }
};