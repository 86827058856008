import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../views/PageNotFound.vue";
import i18n from "@/locales/i18n";

const routes = [
  {
    path: '/',
    meta: {
      target: 'redirect'
    },
    redirect: () => `/${i18n.global.locale.value}`
  },
  {
    path: '/:lang([a-z][a-z])',
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("../views/HomeView.vue"),
        meta: {
          title: "home",
          target: "home"
        },
      },
      {
        path: "chi-siamo",
        name: "ChiSiamo",
        component: () => import("../views/AboutView.vue"),
        meta: {
          title: "about",
          target: "about"
        },
      },
      {
        path: "portfolio",
        name: "Portfolio",
        component: () => import("../views/PortfolioView.vue"),
        meta: {
          title: "portfolio",
          target: "portfolio"
        },
      },
      {
        path: "portfolio/:id",
        name: "PortfolioDetail",
        component: () => import("../views/PortfolioDetailView.vue"),
        meta: {
          title: "portfolioDetail",
          target: "portfolio"
        },
      },
      {
        path: "blog-e-notizie",
        name: "Blog",
        component: () => import("../views/BlogView.vue"),
        meta: {
          title: "blog",
          target: "blog"
        },
      },
      {
        path: "blog-e-notizie/:id",
        name: "BlogDetails",
        component: () => import("../views/BlogDetailView.vue"),
        meta: {
          title: "blogDetail",
          target: "blog"
        },
      },
      {
        path: "contatti",
        name: "Contatti",
        component: () => import("../views/ContactsView.vue"),
        meta: {
          title: "contacts",
          target: "contact"
        },
      }
    ]
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
    meta: {
      title: "Not Found || Tunis VueJS 3 Personal Portfolio Template",
      target: "notFound"
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active'
});

router.beforeEach((to, from, next) => {
  let lang = to.params.lang;
  let langFrom = from.params.lang;
  let noLang = false;
    
  if(!lang || !i18n.global.availableLocales.includes(lang)) {
    lang = langFrom || 'it';
    noLang = true;
  }

  i18n.global.locale.value = lang;

  if(noLang && !['redirect'].includes(to.meta.target)) {
    next('/' + lang + to.path);
  }
  else {
    next();
  }

  window.scrollTo(0, 0);
});

export default router;
