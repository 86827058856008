import configs from "@/configs/configs";

export const datesEN = {
    
};

export const numbersEN = {

};

export const messagesEN = {
    interfaccia: {
        paginator: {
            prev: 'Previous',
            next: 'Next'
        }
    },
    menu: {
        switch: 'Switch ',
        temaScuro: 'Dark',
        temaChiaro: 'Light',
        home: {
            menu: 'Home',
            title: 'Home',
            meta: {
                description: 'All You Can Web creates websites for individuals and companies, logos, AdWords campaigns, various marketing campaigns and everything related to the web'
            }
        },
        about: { 
            menu: 'About',
            menuClass: 'aboutMenuGB',
            title: 'All about us and our history',
            meta: {
                description: 'What is the story of All You Can Web and who are the people who make up the team: developers, graphic designers, illustrators and commercials'
            }
        },
        portfolio: {
            menu: 'Portfolio',
            title: 'Our works for our clients',
            meta: {
                description: 'All the works made by All You Can Web for its customers'
            }
        },
        portfolioDetail: {
            title: '',
            meta: {
                description: 'Work\' details'
            }
        },
        blog: {
            menu: 'Blog',
            title: 'Post, article and news',
            meta: {
                description: 'Articles, posts and news from the All You Can Web world'
            }
        },
        blogDetail: {
            title: '',
            meta: {
                description: 'Post\'s details'
            }
        },
        contacts: {
            menu: 'Contacts',
            title: 'Contact us!',
            meta: {
                description: 'All references of All You Can Web including phone number, email address and contact form'
            }
        },
    },
    home: {
        pageTitle: 'Home - ' + configs.brandName,
        titolo: configs.brandName,
        sottotitolo: 'Chopstick dev',
        description: 'We do not have a fixed price, but we can manage everything you want in a web key: creation of websites for individuals and companies, SEO and indexing activities, development of management software, illustrations, graphic design, logos and much more!',
        pulsanti: {
            about: 'More about us',
            contact: 'Contact us'
        }
    },
    about: {
        pageTitle: 'About us - ' +configs.brandName,
        titolo: 'All You Can <span>Web</span>',
        sottotitolo: 'Our vision',
        intro: '',
        teamTitle: 'About <span>us</span>',
        teamSubtitle: 'Our Team',
        personalInfo: 'Personal infos',
        downloadCV: 'Download CV',
        name: 'Name',
        surname: 'Surname',
        birthday: 'Birthday',
        mail: 'Email',
        phone: 'Phone',
        website: 'Website',
        skillsTitle: 'My Skills',
        intros: {
            giordan: 'Web developer',
            serena: 'Illustrator'
        },
        numbers: {
            giordan: {
                workingYears: {
                    text1: 'Years of',
                    text2: 'experience'
                }
            }
        }
    },
    portfolio: {
        pageTitle: 'Portfolio - ' +configs.brandName,
        titolo: 'Our <span>Portfolio</span>',
        sottotitolo: 'Works'
    },
    contacts: {
        pageTitle: 'Contacts - ' +configs.brandName,
        titolo: 'Stay in  <span>touch</span>',
        sottotitolo: 'Contacts',
        payoff: 'DON\'T BE SHY !',
        description: 'Feel free to get in touch with us. We are always open to discussing new projects, creative ideas or opportunities to be part of your visions!',
        mailLong: 'E-mail address',
        cellPhone: 'Phone',
        writeMe: 'Mail me',
        callMe: 'Call me',
        form: {
            nome: 'Your name',
            mail: 'Your email',
            oggetto: 'Your subject',
            msg: 'Your message',
            send: 'SEND MESSAGE',
            sending: 'SENDING...'
        }
    },
    blog: {
        pageTitle: 'Blog and news - ' +configs.brandName,
        titolo: 'Blog and <span>news</span>',
        sottotitolo: 'Posts',
    },
    notification: {
        buttons: {
            close: 'Close'
        },
        success: {
            contact: 'The message has been successfully sent'
        },
        warning: {
            confirmContactMail: 'The message has been sent, although we have had some problems delivering the confirmation to your email <i class="fa fa-meh-o"></i>'
        },
        error: {
            contactMail: 'Ooops... we were unable to send the message. Please try later <i class="fa fa-frown-o"></i>'
        }
    }
};