<template>
  <main class="yellow">
    <HeaderTab />
    <slot />
  </main>
</template>

<script>
import HeaderTab from '@/components/HeaderTab.vue';
import router from '@/router/index';
import { useHead } from '@vueuse/head';
import { useI18n } from 'vue-i18n';
import i18n from "@/locales/i18n";

export default {
  components: {
    HeaderTab,
  },
  setup() {
    const { t } = useI18n();

      useHead({
        title: () => `${t('menu.' + router.currentRoute.value.meta.title + '.title')}`,
        meta: [
          { name: 'description', content: () => `${t('menu.' + router.currentRoute.value.meta.title + '.meta.description')}` }
        ],
        htmlAttrs: { lang: i18n.global.locale, amp: false }
      });
  }
};
</script>
