let contacts = {
    aycw: {
        mail: 'info@aycw.it',
        phone: '353 44.80.460',
        telegram: 'AllYouCanWeb'
    },
    giordan: {
        mail: 'giordan.massaro{\'@\'}aycw.it',
        phone: '353 44.80.460',
        address: '',
        cap: '',
        city: '',
        prov: '',
        website: 'www.all-you-can-web.it'
    },
    serena: {
        mail: 'naera{\'@\'}naera.it',
        phone: '339 35.97.332',
        address: 'Via Cascina Trinità, snc',
        cap: '24055',
        city: 'Cologno al Serio',
        prov: 'BG',
        website: 'www.naera.it'
    },
    mauro: {
        mail: 'mauro.corbetta.design{\'@\'}gmail.com',
        phone: '371 15.23.053',
        address: 'Via Gabriele Rosa, 18C',
        cap: '24125',
        city: 'Bergamo',
        prov: 'BG',
        website: 'www.maurocorbetta.it'
    },
    simon: {
        mail: 'info{\'@\'}simonlarocca.it',
        phone: '',
        address: '',
        cap: '',
        city: '',
        prov: '',
        website: 'www.simonlarocca.com/'
    },
    fabrizio: {
        mail: 'info{\'@\'}fabrizionaimoli.it',
        phone: '',
        address: '',
        cap: '',
        city: '',
        prov: '',
        website: 'www.fabrizionaimoli.com/'
    },
    methods: {
        clearPhone: (name) => contacts[name].phone.replace(/[\s.]*/gi, ''),
        getPhoneLink: (name) => ('<a href="tel:' + contacts.methods.clearPhone(name) + '">' + contacts[name].phone + '</a>'),
        getMailLink: (name) => ('<a href="mailto:' + contacts[name].mail + '">' + contacts[name].mail + '</a>'),
        getWebsiteLink: (name) => ('<a href="https://' + contacts[name].website  + '" target="_blank">' + contacts[name].website  + '</a>'),
        getFullAddress: (name) => (contacts[name].address + ' - ' + contacts[name].cap + ' - ' + contacts[name].city + ' (' + contacts[name].prov + ')'),
        getWhatsappLink: (name, text) => ('<a href="https://wa.me/39' + contacts.methods.clearPhone(name) + '">' + text + '</a>'),
        getTelegramLink: (name, text) => ('<a href="https://telegram.me/' + contacts[name].telegram + '">' + text + '</a>'),
        getTelegramIconLink: (name) => (contacts.methods.getTelegramLink(name, '<i class="fa fa-telegram"></i>'))
    }
};

export default contacts;