<template>
  <div class="logo-container">
    <router-link to="/">
      <img :src="logoImg" title="All You Can Web" alt="Logo All You Can Web" />
    </router-link>
  </div>
</template>

<script>

export default {
    data() {
        return {
            logoImg: require(`@/assets/images/logo-all-you-can-web.png`)
        };
    }
};
</script>
